
import { defineComponent, onUnmounted, ref } from "vue";
import moment from "moment";
export default defineComponent({
  setup() {
    const TimeTemps = ref("");
    let timer: any = 0;
    // 时间格式转换
    const intervalBeijingTime: any = (str: string): void => {
      const num = new Date(new Date().getTime());
      const Timestamp: any = moment(num);
      const dec = moment(
        new Date(
          new Date(Timestamp).toLocaleString("en", {
            timeZone: "Asia/Shanghai",
          })
        )
      );
      TimeTemps.value = dec.format(str);
      timer = setTimeout(() => {
        intervalBeijingTime(str);
      }, 1000);
    };
    intervalBeijingTime("HH:mm:ss");
    onUnmounted(() => {
      clearTimeout(timer);
    });
    return {
      TimeTemps,
    };
  },
});
