import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "content-layout" }
const _hoisted_3 = { class: "left-layout" }
const _hoisted_4 = { class: "right-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadTop = _resolveComponent("HeadTop")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadTop),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Tabs, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            tag: "div",
            to: '/pc' + item.path,
            class: _normalizeClass(["tab-item", [item.id == _ctx.activeId ? 'active' : '']]),
            key: item.id,
            path: item.path,
            onClick: ($event: any) => (_ctx.showTempById(item.id, item.path))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["to", "path", "class", "onClick"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}