
import { defineComponent, ref } from "vue";
import HeadTop from "@/components/head/index.vue";
interface itemValue {
  name: string;
  id: number | string;
  path: string;
}
export default defineComponent({
  components: {
    HeadTop,
  },
  setup() {
    // 左侧玩法菜单选项
    const Tabs = ref<itemValue[]>([
      { name: "一般体育说明", id: 0, path: "/insBall" },
      { name: "冠军投注", id: 9, path: "/championBall" },
      { name: "足球", id: 1, path: "/footBall" },
      { name: "篮球", id: 2, path: "/basketBall" },
      { name: "排球", id: 3, path: "/vBall" },
      { name: "网球", id: 4, path: "/tennisBall" },
      { name: "桌球", id: 10, path: "/tableBall" },
      { name: "高尔夫球", id: 6, path: "/gofBall" },
      { name: "乒乓球", id: 8, path: "/pingpBall" },
      { name: "羽毛球", id: 7, path: "/badMintonBall" },
      { name: "手球", id: 5, path: "/handBall" },
    ]);
    const activeId = ref<string | number>(0);
    // 根据激活状态展示模板
    const showTempById = (id: number | string): void => {
      activeId.value = id;
    };
    return {
      Tabs,
      activeId,
      showTempById,
    };
  },
});
